import React from "react";
import { assets } from "@koble/assets/assets";
import { IdentityUserType } from "@koble/utils/src/UserSessionManager";
import { Col, Row, Typography } from "antd";

const CommonUnsettledLikesReceivedBanner = ({
  likesReceivedCount,
  userType,
  isMobile,
}: {
  likesReceivedCount: number;
  userType: IdentityUserType;
  isMobile: boolean;
}) => {
  return (
    <div
      key="likes-received-banner"
      style={{
        padding: "10px 15px",
        backgroundColor: "#faad14",
        color: "white",
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      }}
    >
      <Row style={{ justifyContent: "space-between" }}>
        <Col style={{ display: "flex", alignItems: "center" }}>
          <img alt="Kobot" height={20} src={assets.KOBOT_HAPPY_EXTRA_SMALL} />
          {isMobile ? (
            <Typography.Text style={{ color: "white", marginLeft: 10 }}>
              ¡Tienes <strong>{likesReceivedCount}</strong>{" "}
              {likesReceivedCount > 1 ? "likes" : "like"} pendientes!
            </Typography.Text>
          ) : (
            <Typography.Text style={{ color: "white", marginLeft: 10 }}>
              ¡Tienes <strong>{likesReceivedCount}</strong>{" "}
              {userType === "userStudent" ? "reclutadores " : "talentos "}
              interesados en ti! Gestiona{" "}
              {likesReceivedCount > 1 ? "tus likes" : "tu like"} recibidos
            </Typography.Text>
          )}
        </Col>

        <Col style={{ display: "flex", alignItems: "center" }}>
          <a href="/likes-received" style={{ marginRight: 10 }}>
            {isMobile ? (
              <Typography.Text style={{ color: "white" }}>
                Ir a interesados
              </Typography.Text>
            ) : (
              <Typography.Text style={{ color: "white" }}>
                Ir a interesados en mí
              </Typography.Text>
            )}
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default CommonUnsettledLikesReceivedBanner;
